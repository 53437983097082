<template>
  <div class="mb-4">
    <v-text-field
      v-model="title"
      label="Title*"
      placeholder="Enter title"
      clearable
      @input="update"
    />
    <v-text-field
      v-model="tagline"
      label="Tagline"
      placeholder="Enter tagline"
      clearable
      @input="update"
    />
    <p class="color-label">
      Color
    </p>
    <div class="color-container mb-4">
      <v-btn
        color="white"
        right
        :class="{ 'active': type === 'normal' }"
        @click="updateType('normal')"
      />
      <v-btn
        color="red"
        right
        :class="{ 'active': type === 'extra_kick' }"
        @click="updateType('extra_kick')"
      />
      <v-btn
        color="black"
        right
        :class="{ 'active': type === 'ask_mark' }"
        @click="updateType('ask_mark')"
      />
    </div>
    <PageDetails :page-number="page.pageNumber" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageDetails from './PageDetails';

export default {
  components: {
    PageDetails
  },
  props: ['page'],
  data() {
    return {
      title: '',
      tagline: '',
      type: '',
      pageContentLanguage: null
    };
  },
  computed: {
    ...mapGetters([
      'currentLanguage'
    ])
  },
  watch: {
    currentLanguage() {
      this.getContent();
    }
  },
  created() {
    this.getContent();
  },
  methods: {
    getContent() {
      this.pageContentLanguage = this.page.content[this.currentLanguage];
      const { title, tagline, type } = this.pageContentLanguage;

      this.title = title;
      this.tagline = tagline;
      this.type = type;
    },
    updateType(type) {
      this.type = type;
      this.update();
    },
    update() {
      const content = {
        ...this.page.content[this.currentLanguage],
        title: this.title,
        tagline: this.tagline,
        type: this.type
      };
      this.$store.dispatch('updatePageContent', { pageNumber: this.page.pageNumber, content });
    }
  }
};
</script>

<style scoped lang="scss">
  .color-label {
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
    margin: 0;
  }
  .color-container {
    margin-left: 0.5rem;
    margin-bottom: 1rem;
    .v-btn {
      min-width: 28px;
      height: 28px;
      margin-right: 0.2rem;
    }
    .v-btn {
      &.active {
        border: solid 2px #1976d3 !important;
      }
    }
  }
</style>
