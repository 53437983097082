import 'core-js/es';
import 'vuetify/dist/vuetify.min.css';

import { defaultSanitizeOptions } from '@kickbox/common-util';
import { Icons, AppButton } from '@kickbox/common-admin';
import VueRuid from '@kickbox/common-util/plugins/ruid';
import Parse from '@kickbox/common-util/src/parse';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import Vue from 'vue';
import Vuetify from 'vuetify';
import Croppa from 'vue-croppa';
import JsonExcel from 'vue-json-excel';
import VueLodash from 'vue-lodash';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import VueSanitize from 'vue-sanitize';

import App from './App';
import router from './router';
import store from './store';
import '@mdi/font/css/materialdesignicons.css';

const isDev = process.env.NODE_ENV === 'development';

// Configure Sentry
Sentry.init({
  release: `kickbox-meta@${process.env.VUE_APP_VERSION}`,
  dsn: isDev ? '' : 'https://c1de75c9d4c640598fbf3815fd623856@sentry.io/1393272',
  environment: process.env.NODE_ENV,
  integrations: [
    new VueIntegration({
      Vue,
      attachProps: true,
    })
  ]
});

// Configure Vue
Vue.config.productionTip = false;
Vue.component('downloadExcel', JsonExcel);
Vue.component('app-button', AppButton);
Vue.filter('capitalize', (value) => (value ? value.toString().toUpperCase() : ''));
Vue.use(Croppa, { componentName: 'select-image' });
Vue.use(VueLodash, { name: '$lodash', lodash: { difference, uniq } });
Vue.use(VueSanitize, defaultSanitizeOptions);
Vue.use(VueRuid);
Vue.use(Vuetify);

new Vue({
  async created() {
    const user = Parse.User.current();

    if (user) {
      const sessionToken = user.get('sessionToken');
      try {
        await Parse.User.become(sessionToken);
        router.push({ name: 'Home' });
      } catch (error) {
        console.error('Invalid session', error);
        Parse.User.logOut();
      }
    }
  },
  vuetify: new Vuetify({
    icons: {
      iconfont: 'mdi',
      values: Icons
    },
    theme: {
      themes: {
        light: {
          primary: '#2b94fc',
        },
      },
    },
  }),
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
