import Vue from 'vue';
import Vuex from 'vuex';
import { SnackBarStore, LoaderStore, LayoutStore } from '@kickbox/common-admin';
import companies from './companies';
import user from './user';
import tags from './tags';
import extraMile from './extraMile';
import defaultContent from './defaultContent';

Vue.use(Vuex);
const isDev = process.env.NODE_ENV === 'development';

export default new Vuex.Store({
  strict: isDev,
  modules: {
    companies,
    user,
    tags,
    extraMile,
    defaultContent,
    SnackBarStore,
    LoaderStore,
    LayoutStore
  }
});
