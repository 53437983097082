<template>
  <v-row>
    <v-col>
      <LanguagesBase
        :languages="languages"
        @save="save"
      >
        <v-row>
          <v-col
            class="px-5 pt-5"
          >
            <p class="title">
              {{ chapterName }}
            </p>
            <Page
              v-for="page in pages"
              :key="page.id"
              :page="page"
            />
          </v-col>
        </v-row>
      </LanguagesBase>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import bookService from '@/services/bookService';
import LanguagesBase from './LanguagesBase';
import Page from './Page';

export default {
  name: 'ExtraMilePages',
  components: {
    LanguagesBase,
    Page
  },
  props: ['chapter'],
  computed: {
    ...mapGetters([
      'pages',
      'currentLanguage',
      'languages'
    ]),
    chapterName() {
      const name = this.chapter && this.chapter.content[this.currentLanguage].name
        ? this.chapter.content[this.currentLanguage].name : this.chapter.content.EN.name;
      return `Chapter - ${name}`;
    }
  },
  async created() {
    const allChapterPages = await bookService.getBookPages(this.chapter.chapterNumber);
    const pagesPerLanguage = allChapterPages.map((page) => {
      const pageWithLanguages = {
        id: page.id,
        pageNumber: page.pageNumber,
        thumbnail: page.thumbnail,
        content: {}
      };
      this.languages.forEach((language) => {
        const pageLanguage = page.content[language];
        if (pageLanguage) {
          pageWithLanguages.content[language] = pageLanguage;
        } else {
          pageWithLanguages.content[language] = {
            title: '',
            tagline: '',
            type: 'normal',
            buttons: [
              { title: '', link: '' }
            ]
          };
        }
      });
      return pageWithLanguages;
    });
    this.$store.commit('setPages', pagesPerLanguage);
  },
  methods: {
    async save() {
      if (this.invalidValues()) {
        this.$store.commit('showSnackBar', { text: 'At least 1 page title is missing.' });
      } else {
        await bookService.saveBookPages(this.pages, this.chapter.id);
        this.$store.commit('showSnackBar', { text: 'The pages were saved.' });
      }
    },
    invalidValues() {
      return this.pages.some((c) => !c.content[this.currentLanguage].title);
    },
  }
};
</script>

<style scoped>
  .title {
    margin-bottom: 1.5rem;
    color: #414141;
  }
</style>
