<template>
  <v-container
    class="text-center"
  >
    <v-row>
      <v-col cols="3">
        <v-card
          dark
          color="teal"
        >
          <counter
            :count="companies.length"
            subtitle="Companies"
            icon="domain"
          />
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          dark
          color="teal"
        >
          <counter
            :count="userCount"
            subtitle="Active users"
            icon="person"
          />
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          dark
          color="teal"
        >
          <counter
            :count="offerCount"
            subtitle="Published offers"
            icon="local_offer"
          />
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          dark
          color="teal"
        >
          <counter
            :count="projectCount"
            subtitle="Active projects"
            icon="assignment"
          />
        </v-card>
      </v-col>

      <v-col cols="12">
        <div class="select-container">
          <v-autocomplete
            :items="companies"
            label="Company"
            @change="getCompanyData"
          />
        </div>
      </v-col>
      <v-col cols="6">
        <v-card
          color="white"
        >
          <p class="card-title">
            Breakdown of project phases by company
          </p>
          <horizontal-bar-chart
            :chart-data="phaseData"
            :options="{
              responsive: true,
              maintainAspectRatio: false,
              scales: { xAxes: [{ stacked: true }], yAxes: [{ stacked: true }] }
            }"
            class="height small-padding"
          />
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          color="white"
        >
          <p class="card-title">
            Breakdown of offer statuses by company
          </p>
          <horizontal-bar-chart
            :chart-data="offerData"
            :options="{
              responsive: true,
              maintainAspectRatio: false,
              scales: { xAxes: [{ stacked: true }], yAxes: [{ stacked: true }] }
            }"
            class="height small-padding"
          />
        </v-card>
      </v-col>

      <v-col cols="8">
        <v-card
          color="white"
        >
          <p class="card-title">
            Projects created by month
          </p>
          <line-chart
            :chart-data="projectData"
            :options="{
              responsive: true,
              maintainAspectRatio: false,
            }"
            class="height big-padding"
          />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card
          color="white"
        >
          <p class="card-title">
            Breakdown of user languages
          </p>
          <dougnut-chart
            :chart-data="languageData"
            :options="{ responsive: true, maintainAspectRatio: false }"
            class="height big-padding"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Parse from '@kickbox/common-util/src/parse';
import Counter from './charts/Counter';
import DougnutChart from './charts/Doughnut';
import HorizontalBarChart from './charts/Bar';
import LineChart from './charts/Line';

export default {
  components: {
    DougnutChart,
    HorizontalBarChart,
    LineChart,
    Counter
  },
  data() {
    return {
      companies: [],
      languageData: {},
      projectData: {},
      phaseData: {},
      offerData: {},
      userCount: 0,
      offerCount: 0,
      projectCount: 0,
    };
  },
  async mounted () {
    // eslint-disable-next-line
    Chart.defaults.global.defaultFontColor = '#3f51b5';

    this.companies = await this.getCompanies();

    this.userCount = await this.getAmountOfActiveUsers();
    this.offerCount = await this.getAmountOfOffers();
    this.projectCount = await this.getAmountOfProjects();

    this.languageData = await this.getLanguageDate();
    this.projectData = await this.getProjectData();
  },
  methods: {
    getCompanyData(companyName) {
      this.getPhaseData(companyName);
      this.getOfferData(companyName);
    },
    async getCompanies() {
      const companyQuery = new Parse.Query('Company');
      companyQuery.select('name');
      companyQuery.equalTo('enabled', true);
      companyQuery.limit(10000);
      const companies = await companyQuery.find();
      return companies.map((company) => company.get('name'));
    },
    getProjectsByCompany(companyName) {
      const companyQuery = new Parse.Query('Company');
      companyQuery.equalTo('name', companyName);

      const projectQuery = new Parse.Query('Project');
      projectQuery.matchesQuery('company', companyQuery);
      projectQuery.notEqualTo('disabled', true);
      projectQuery.include('phase');
      projectQuery.limit(10000);
      return projectQuery.find();
    },
    getProjectsUntilDate(toDate) {
      const query = new Parse.Query('Project');
      const fromDate = new Date(toDate.getTime());
      fromDate.setMonth(fromDate.getMonth() - 6);
      query.greaterThanOrEqualTo('createdAt', fromDate);
      query.lessThanOrEqualTo('createdAt', toDate);
      query.ascending('createdAt');
      return query.find();
    },
    getUsers() {
      const userQuery = new Parse.Query('User');
      userQuery.notEqualTo('disabled', true);
      userQuery.limit(10000);
      return userQuery.find();
    },
    getRequestByCompany(companyName) {
      const companyQuery = new Parse.Query('Company');
      companyQuery.equalTo('name', companyName);

      const projectQuery = new Parse.Query('Project');
      projectQuery.matchesQuery('company', companyQuery);

      const requestQuery = new Parse.Query('Request');
      requestQuery.matchesQuery('project', projectQuery);
      return requestQuery.find();
    },
    getAmountOfActiveUsers() {
      const query = new Parse.Query('_User');
      query.notEqualTo('disabled', true);
      query.equalTo('emailVerified', true);
      return query.count();
    },
    getAmountOfOffers() {
      const query = new Parse.Query('Offer');
      query.equalTo('isPublished', true);
      return query.count();
    },
    getAmountOfProjects() {
      const Project = Parse.Object.extend('Project');
      const Phase = Parse.Object.extend('Phase');
      const innerQuery = new Parse.Query(Phase);
      innerQuery.notEqualTo('name', 'pending');
      const query = new Parse.Query(Project);
      query.notEqualTo('disabled', true);
      query.matchesQuery('phase', innerQuery);
      return query.count();
    },
    async getPhaseData(selectedCompany) {
      const projects = await this.getProjectsByCompany(selectedCompany);
      const phaseTypes = {};

      projects.forEach((project) => {
        const phaseType = project.get('phase') && project.get('phase').get('title').toLowerCase();
        if (!phaseTypes[phaseType]) {
          phaseTypes[phaseType] = 0;
        }

        phaseTypes[phaseType] += 1;
      });

      this.phaseData = {
        labels: [selectedCompany],
        datasets: [
          {
            label: 'Pending',
            backgroundColor: 'rgba(141,152,165, 0.6)',
            borderColor: 'rgb(141,152,165)',
            data: [phaseTypes.pending || 0]
          },
          {
            label: 'Redbox',
            backgroundColor: 'rgba(255,74,74, 0.6)',
            borderColor: 'rgb(255,74,74)',
            data: [phaseTypes.redbox || 0]
          },
          {
            label: 'Bluebox',
            backgroundColor: 'rgba(43,148,252, 0.6)',
            borderColor: 'rgb(43,148,252)',
            data: [phaseTypes.bluebox || 0]
          },
          {
            label: 'Goldbox',
            backgroundColor: 'rgba(245,166,35, 0.6)',
            borderColor: 'rgb(245,166,35)',
            data: [phaseTypes.goldbox || 0]
          }
        ]
      };
    },
    async getProjectData() {
      // Gets amounts of projects created for each month (for 6 months)
      const projects = await this.getProjectsUntilDate(new Date());

      const projectData = {};
      projects.forEach((project) => {
        const date = project.createdAt;
        const key = `${date.getFullYear()}/${date.getMonth() + 1}`;
        const count = projectData[key];
        projectData[key] = count ? count + 1 : 1;
      });

      const labels = Object.keys(projectData);
      const data = Object.values(projectData);

      return {
        labels,
        datasets: [
          {
            label: 'Amount of projects created',
            backgroundColor: 'rgba(255, 165, 0, 0.6)',
            borderColor: 'rgb(255, 165, 0)',
            data
          }
        ]
      };
    },
    async getLanguageDate() {
      const users = await this.getUsers();

      const languages = users.map((user) => user.get('preferredLanguage'));
      const languageCount = languages.reduce((acc, curr) => {
        acc[curr] = acc[curr] ? acc[curr] + 1 : 1;
        return acc;
      }, {});

      const englishColor = 'rgba(0, 0, 255, 0.6)';
      const germanColor = 'rgba(255, 255, 0, 0.6)';
      const frenchColor = 'rgba(255, 0, 0, 0.6)';
      const italianColor = 'rgba(0, 255, 0, 0.6)';

      return {
        labels: ['English', 'German', 'French', 'Italian'],
        datasets: [
          {
            label: 'Active projects',
            backgroundColor: [englishColor, germanColor, frenchColor, italianColor],
            data: [languageCount.EN, languageCount.DE, languageCount.FR, languageCount.IT]
          }
        ]
      };
    },
    async getOfferData(selectedCompany) {
      const requests = await this.getRequestByCompany(selectedCompany);
      const statuses = {};

      requests.forEach((request) => {
        const status = request.get('status');

        if (!statuses[status]) {
          statuses[status] = 0;
        }

        statuses[status] += 1;
      });

      this.offerData = {
        labels: [selectedCompany],
        datasets: [
          {
            label: 'Open',
            backgroundColor: 'rgb(43,148,252, 0.6)',
            borderColor: 'rgb(43,148,252)',
            data: [statuses.open || 0]
          },
          {
            label: 'Accepted',
            backgroundColor: 'rgba(121,196,38, 0.6)',
            borderColor: 'rgb(121,196,38)',
            data: [statuses.accepted || 0]
          },
          {
            label: 'Rejected',
            backgroundColor: 'rgba(255,74,74, 0.6)',
            borderColor: 'rgb(255,74,74)',
            data: [statuses.rejected || 0]
          },
          {
            label: 'Cancelled',
            backgroundColor: 'rgba(141,152,165, 0.6)',
            borderColor: 'rgb(141,152,165)',
            data: [statuses.cancelled || 0]
          }
        ]
      };
    }
  }
};
</script>

<style scoped>
  .xs3 {
    padding: 0 4px 10px 4px !important;
  }
  .card-title {
    float: left;
    margin: 15px 0 0 15px;
    color: #3f51b5;
  }
  .height {
    height: 300px !important;
    box-sizing: content-box;
    position: relative;
  }

  .small-padding {
    padding: 30px 10px 15px 10px;
  }

  .big-padding {
    padding: 40px 10px 15px 10px;
  }

  .select-container {
    padding: 10px 15px 0 15px;
  }

  @media (max-width: 480px) {
    .layout.row {
      flex-direction: column;
    }

    .flex.xs3, .flex.xs6, .flex.xs8, .flex.flex.xs4 {
      max-width: 100%;
    }
  }
</style>
