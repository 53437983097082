<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="10"
        lg="8"
        xl="6"
      >
        <v-card>
          <v-container
            fluid
          >
            <v-row>
              <v-col cols="9">
                <v-subheader>Current languages</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-subheader>
                  Default
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        color="primary"
                        v-on="on"
                      >
                        info
                      </v-icon>
                    </template>
                    <span>
                      Select the default languages that are going to be listed
                      when signing up<br>
                      and selected by default when creating a new company.<br>
                    </span>
                  </v-tooltip>
                </v-subheader>
              </v-col>
              <v-col>
                <v-list>
                  <template
                    v-for="language in languages"
                  >
                    <v-list-item
                      :key="language.key"
                      ripple
                      @click.stop
                    >
                      <v-list-item-content @click="goToTranslation(language.key)">
                        <v-list-item-title class="v-list--tile--title--auto-height">
                          <v-icon>input</v-icon>
                          {{ language.name }}
                          <v-tooltip
                            v-if="userContentTranslationNotSupported(language.key)"
                            bottom
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon
                                small
                                color="warning"
                                v-on="on"
                              >
                                warning
                              </v-icon>
                            </template>
                            <span>
                              This language isn't supported by DeepL!
                            </span>
                          </v-tooltip>
                          <v-chip
                            v-if="emptyTranslations[language.key] > 0"
                            color="red"
                            text-color="white"
                          >
                            {{ emptyTranslations[language.key] }} missing translations
                          </v-chip>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox
                          v-model="language.defaultLanguage"
                          @change="saveLanguage(language)"
                        />
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>

            <v-divider class="pb-12" />

            <v-autocomplete
              ref="newLanguage"
              v-model="newLanguage"
              :items="allNewLanguages"
              :rules="[rules.required]"
              item-text="name"
              item-value="key"
              label="Select a new language"
              class="pb-2"
              return-object
            />

            <app-button @click="openConfirmationDialog">
              Add Language
            </app-button>
            <confirm-dialog
              :show="showAddLanguageDialog"
              :confirm-click="addLanguage"
              @close="closeConfirmationDialog"
            >
              Do you want to add the language "{{ newLanguage.name }}"?
            </confirm-dialog>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { ConfirmDialog, ValidateMixin } from '@kickbox/common-admin';
import { DEEPL_SUPPORTED_LANGUAGES } from '@kickbox/common-util/constants/deepl-supported-languages';
import allLanguages from '@kickbox/common-util/constants/languages';
import languageService from '@/services/languageService';

export default {
  components: {
    ConfirmDialog
  },
  mixins: [ValidateMixin],
  data() {
    return {
      search: '',
      searchResult: [],
      languages: [],
      newLanguage: '',
      showAddLanguageDialog: false,
      emptyTranslations: {}
    };
  },
  computed: {
    ...mapGetters([
      'isLoading'
    ]),
    formMandatoryFields() {
      return {
        newLanguage: this.newLanguage
      };
    },
    noData() {
      return this.isLoading.languages ? 'loading...' : 'No languages.';
    },
    allNewLanguages() {
      // Filter out the existing languages
      return allLanguages
        .filter((lang) => !this.languages.find((currentLang) => currentLang.key === lang.key));
    }
  },
  async created() {
    this.$store.dispatch('setIsLoading', { languages: true });
    this.languages = await languageService.getLanguages();
    this.languages.forEach((l) => {
      languageService.getAllKeys(l.key).then((translations) => {
        this.$set(this.emptyTranslations, l.key, translations.filter((t) => !t[l.key]).length);
      });
    });
    this.$store.dispatch('setIsLoading', { languages: false });
  },
  methods: {
    userContentTranslationNotSupported(locale) {
      return !DEEPL_SUPPORTED_LANGUAGES.includes(locale);
    },
    openConfirmationDialog() {
      if (this.checkFields()) {
        this.showAddLanguageDialog = true;
      }
    },
    closeConfirmationDialog() {
      this.showAddLanguageDialog = false;
    },
    async addLanguage() {
      this.closeConfirmationDialog();
      const newLanguage = await languageService.addLanguage(this.newLanguage);
      const newLanguageObj = languageService.convertLanguageObj(newLanguage);
      this.languages.push(newLanguageObj);
      this.resetFieldValidation();
    },
    saveLanguage(language) {
      languageService.saveLanguage(language);
    },
    goToTranslation(key) {
      this.$router.push({ path: `translations/${key}` });
    }
  }
};
</script>

<style scoped>
  .v-input--selection-controls {
    margin: 0 0 0 20px;
    padding: 0;
  }
  .v-icon {
    margin-right: 10px;
  }
  .v-subheader .v-icon {
    margin-left: 5px;
  }
  .v-list--tile--title--auto-height{
    height: auto;
  }
</style>
