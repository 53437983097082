<template>
  <v-card class="pt-2">
    <v-card-text>
      <label class="d-block mb-2">
        Company Logo*
      </label>
      <select-image
        v-model="logo"
        v-ruid="'image'"
        :placeholder="'Choose or drag a logo'"
        :placeholder-font-size="18"
        :placeholder-color="'default'"
        :file-size-limit="716800"
        :remove-button-color="'#3f51b5'"
        :remove-button-size="20"
        :zoom-speed="7"
        :width="220"
        :height="70"
        accept=".svg,.png,.jpg"
        canvas-color="transparent"
        @move="imageMovedOrResized"
        @zoom="imageMovedOrResized"
      >
        <img
          slot="initial"
          crossOrigin="anonymous"
          :src="`${initialImage}?t=${getRandomInt}`"
        >
      </select-image>
      <label class="d-block">
        Adjust the image to fit the shape above (move and zoom).
      </label>
      <v-text-field
        ref="name"
        v-model="name"
        v-ruid="'name'"
        :rules="[() => !!name || 'This field is required']"
        label="Company Name*"
        clearable
        placeholder="Enter a company name"
        class="mt-4"
        required
      />
      <v-combobox
        ref="domain"
        v-model="domain"
        v-ruid="'domains'"
        :rules="[rules.atLeastOneItem, rules.uniqueDomain(domain, domainReference)]"
        label="Company Domains*"
        placeholder="Add or remove a domain"
        chips
        multiple
        clearable
        append-icon
        validate-on-blur
        required
        class="mt-4"
      >
        <template
          slot="selection"
          slot-scope="data"
        >
          <v-chip
            :input-value="data.selected"
            close
            small
            @click:close="removeDomain(domain, data.item)"
          >
            {{ data.item }}
          </v-chip>
        </template>
      </v-combobox>
      <v-select
        v-model="features"
        v-ruid="'features'"
        :items="allFeatures"
        label="Available Features"
        multiple
        placeholder="Select"
        class="mt-4"
        item-text="name"
      />
      <v-select
        ref="enabledLanguages"
        v-model="enabledLanguages"
        v-ruid="'languages'"
        :rules="[rules.atLeastOneItem]"
        :items="allLanguages"
        label="Available Languages*"
        multiple
        placeholder="Select"
        class="mt-4"
        item-text="name"
        item-value="key"
        @change="checkDefaultLanguage"
      />
      <v-autocomplete
        ref="language"
        v-model="language"
        v-ruid="'defaultLanguage'"
        :rules="[rules.required]"
        :items="enabledLanguages"
        label="Default Language*"
        placeholder="Select"
        class="mt-4"
        item-text="name"
        item-value="key"
      />
      <v-text-field
        ref="analyticsId"
        v-model="analyticsId"
        v-ruid="'analyticsId'"
        label="Google Analytics ID"
        clearable
        placeholder="Enter analytics id"
        class="mt-4"
      />
      <v-subheader>SSO Settings</v-subheader>
      <v-text-field
        ref="ssoIdentifier"
        v-model="ssoData.serviceIdentifier"
        v-ruid="'ssoService'"
        label="SSO Service"
        clearable
        placeholder="Enter the name of the sso identifier"
        class="mt-4"
      />
      <v-checkbox
        v-model="ssoData.signed"
        v-ruid="'ssoSign'"
        hide-details
        label="Sign the SSO request"
        color="primary"
        class="mt-1"
      />
      <v-checkbox
        v-model="ssoData.usePost"
        v-ruid="'ssoUsePost'"
        hide-details
        label="Do a POST request"
        color="primary"
        class="mt-1"
      />
      <v-checkbox
        v-model="ssoData.passOnEmail"
        v-ruid="'ssoUserEmail'"
        hide-details
        label="Pass the user's email to the company SSO"
        color="primary"
        class="mt-1"
      />
      <v-radio-group
        v-if="availablePlans"
        ref="plan"
        v-model="plan"
        v-ruid="'subscriptionPlan'"
        hide-details
      >
        <div slot="label">
          Subscription plan*
        </div>
        <v-radio
          v-for="availablePlan in availablePlans"
          :key="availablePlan.id"
          :label="overridePlanName(availablePlan.get('name'))"
          :name="availablePlan.get('name')"
          :value="availablePlan.id"
          color="primary"
        />
      </v-radio-group>
      <div class="mt-4">
        <label class="body-1">
          Please be sure that you have at least one admin and unit before enable the company
        </label>
        <v-checkbox
          v-model="enabled"
          v-ruid="'enabled'"
          hide-details
          label="Enable company"
          color="primary"
          class="mt-1"
        />
      </div>
      <div class="my-2">
        <app-button
          v-ruid="'saveGeneral'"
          :loading="loadingButton"
          class="mt-4"
          @click="manageCompany"
        >
          {{ buttonText }}
        </app-button>
      </div>
      <label class="body-1">* Mandatory field</label>
    </v-card-text>
  </v-card>
</template>

<script>
import slug from 'slug';
import {
  CompanyService, EntityService, ValidateMixin
} from '@kickbox/common-admin';
import { getRandomInt } from '@kickbox/common-util';
import {
  removeItemFromCollection
} from '@kickbox/common-util/src/util/removeItemFromCollection';

import FEATURE_NAMES from '@kickbox/common-util/constants/feature-names';
import languageService from '@/services/languageService';

export default {
  mixins: [ValidateMixin],
  props: {
    company: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      id: '',
      name: null,
      domain: null,
      domainReference: null,
      initialImage: '',
      buttonText: 'Create',
      allFeatures: [
        ...Object.values(FEATURE_NAMES)
      ],
      features: [],
      enabledLanguages: [],
      analyticsId: '',
      plan: '',
      logo: {},
      currentPlan: '',
      ssoData: {
        signed: false,
        usePost: false,
        passOnEmail: true,
        serviceIdentifier: ''
      },
      availablePlans: [],
      loadingButton: false,
      enabled: true,
      language: '',
      allLanguages: []
    };
  },
  computed: {
    formMandatoryFields() {
      return {
        name: this.name,
        plan: this.plan,
        domain: this.domain,
        language: this.language
      };
    },
    getRandomInt() {
      return getRandomInt(100);
    },
    formFields() {
      return {
        name: this.name,
        slug: slug(this.name, { lower: true }),
        domain: this.domain,
        features: this.features,
        enabledLanguages: this.enabledLanguages,
        analyticsId: this.analyticsId,
        ssoData: this.ssoData,
        enabled: this.enabled,
        language: this.language
      };
    },
    defaultLanguages() {
      return this.allLanguages
        .filter((lang) => lang.defaultLanguage)
        .map((l) => l.key);
    }
  },
  async created() {
    [this.allLanguages, this.availablePlans] = await Promise.all([
      languageService.getLanguages(),
      CompanyService.getPlans()
    ]);
    if (this.company) {
      this.id = this.company.id;
      this.initialImage = this.company.logo;
      // select-image component is initialized before get the initialImage property.
      this.logo.refresh();
      this.name = this.company.name;
      this.domain = [...this.company.domain];
      this.domainReference = [...this.company.domain];
      this.features = [...this.company.features];
      this.enabled = this.company.enabled;
      this.ssoData = this.company.ssoData;
      this.analyticsId = this.company.analyticsId;
      this.buttonText = 'Save';
      this.currentPlan = await CompanyService.getCompanyPlan(this.company.id);
      this.plan = this.currentPlan ? this.currentPlan.id : null;
      this.language = this.company.language;
      this.enabledLanguages = this.company.enabledLanguages;
    } else {
      this.enabledLanguages = this.defaultLanguages;
      this.plan = this.availablePlans[0].id;
      this.features = [];
    }
  },
  methods: {
    overridePlanName(plan) {
      const config = {
        basic: 'PRO',
        starter: 'LIGHT'
      };
      return plan && config.hasOwnProperty(plan) ? config[plan] : plan;
    },
    async isSsoDataValid() {
      if (this.features.includes(FEATURE_NAMES.SSO)) {
        try {
          const signed = this.ssoData.signed ? '/signed' : '';
          const response = this.ssoData.serviceIdentifier && await fetch(`${process.env.VUE_APP_PARSE_SERVER_URL}/../rest/saml/${this.ssoData.serviceIdentifier}/logininfo${signed}`);
          return response && response.status === 200;
        } catch (error) {
          return false;
        }
      }

      return true;
    },
    async removeDomain(array, item) {
      removeItemFromCollection(array, item);
    },

    checkDefaultLanguage(languages) {
      // Check the language otherwise it the language change will trigger the validation
      if (this.language && !languages.includes(this.language)) {
        this.language = null;
      }
    },
    async manageCompany() {
      if (!this.logo.hasImage()) {
        this.$store.dispatch('showSnackBar', { text: 'You need to add a company logo.' });
        return;
      }

      if (!(await this.isSsoDataValid())) {
        this.$store.dispatch('showSnackBar', { text: 'The input for SSO identifier isn\'t correct.' });
        return;
      }
      if (this.checkFields()) {
        this.loadingButton = true;
        if (this.buttonText === 'Create') {
          this.createCompany();
        } else {
          this.editCompany();
        }
        this.loadingButton = false;
      }
    },
    async createCompany() {
      const company = await CompanyService.createCompany(this.logo, this.formFields);
      if (!this.currentPlan || this.currentPlan.id !== this.plan) {
        CompanyService.subscribeToPlan(company.id, this.plan);
      }
      this.$store.commit('addCompany', company);
      this.$router.push({ name: 'EditCompany', params: { slug: company.slug, startingTab: 1 } });
    },
    async editCompany() {
      const entity = this.getValidatedEntityProperties(this.formFields);
      const imageProperty = {
        file: this.logo,
        oldUrl: this.company.logo
      };
      const company = await EntityService
        .updateEntity('Company', this.id, entity.setProperties, entity.unsetProperties, imageProperty);
      if (!this.currentPlan || this.currentPlan.id !== this.plan) {
        CompanyService.changePlan(company.id, this.plan);
      }
      this.$store.commit('updateCompany', company);
      this.$router.push({ name: 'CompaniesManage' });
    },
    imageMovedOrResized() {
      this.logo.currentIsInitial = false;
    }
  }
};
</script>

<style scoped>
.input-group {
  padding-top: 10px !important;
}

.tile-container {
  border-bottom: 1px solid #d0cbcb !important;
}

.list {
  padding: 0 !important;
}

.list__tile__action {
  min-width: 30px !important;
}

.list__tile__title {
  font-size: 14px !important;
}

.list .btn .btn__content .icon {
  color: #7b7676 !important;
}

label {
  color: rgba(0, 0, 0, .54);
  font-size: 12px;
}

.error-label {
  color: red;
}

.croppa-container {
  border: 1px solid grey;
}

.flex.xs5 {
  min-width: 100%;
}

.input-group .radio {
  padding-top: 0 !important;
}

</style>
