import store from '@/store';
import Parse from '@kickbox/common-util/src/parse';

export default {
  getBookChapters() {
    return Parse.Cloud.run('getBookChapters');
  },
  getBookPages(chapterNumber) {
    return Parse.Cloud.run('getBookPages', { chapterNumber });
  },
  saveBookChapters(chapterArray) {
    return this.saveExtraMileObject('BookChapter', chapterArray);
  },
  saveBookPages(pageArray, chapterId) {
    return this.saveExtraMileObject('BookPage', pageArray, chapterId);
  },
  saveExtraMileObject(className, objectArray, chapterId) {
    const language = store.getters.currentLanguage;
    return Parse.Cloud.run('saveExtraMileObject', { className, objectArray, language, chapterId });
  },
};
