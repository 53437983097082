<template>
  <v-row>
    <v-col
      cols="12"
      offset-sm="1"
      sm="10"
      lg="9"
      xl="8"
    >
      <v-tabs
        v-model="active"
        centered
        background-color="#ebebeb"
        grow
        slider-color="primary"
        show-arrows
      >
        <v-tab ruid="tab_general">
          General
        </v-tab>
        <v-tab ruid="tab_units">
          Units
        </v-tab>
        <v-tab ruid="tab_company_admins">
          Company Admins
        </v-tab>
        <v-tab
          v-if="company.features.includes(FEATURE_NAME.MULTI_ENTITY)"
          ruid="tab_unit_admins"
        >
          Unit Admins
        </v-tab>
        <v-tab ruid="tab_coaches">
          Coaches
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="active">
        <v-tab-item>
          <app-form-company :company="company" />
        </v-tab-item>
        <v-tab-item>
          <v-card class="pa-4">
            <app-form-units
              :company="company"
              @saved="goToCompanyList"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <app-admin-user-list
            :admin-user-emails="company.admins || []"
            :domains="company.domain"
            label="Company Admins"
            :list-item-actions="listItemActions.admins"
            :min-count="1"
            :is-loading="loading.admins"
            :check-if-emails-exists="checkIfEmailsExists"
            @save="save(ADMIN_ROLES.COMPANY_ADMINS, $event)"
          />
        </v-tab-item>
        <v-tab-item
          v-if="company.features.includes(FEATURE_NAME.MULTI_ENTITY)"
        >
          <app-admin-user-list
            :admin-user-emails="company.unitAdmins || []"
            :domains="company.domain"
            label="Unit Admins"
            :list-item-actions="listItemActions.unitAdmins"
            :is-loading="loading.unitAdmins"
            :check-if-emails-exists="checkIfEmailsExists"
            @save="save(ADMIN_ROLES.UNIT_ADMINS, $event)"
          />
        </v-tab-item>
        <v-tab-item>
          <app-admin-user-list
            :admin-user-emails="company.coaches || []"
            :domains="company.domain"
            label="Coaches"
            :list-item-actions="listItemActions.coaches"
            :is-loading="loading.coaches"
            :check-if-emails-exists="checkIfEmailsExists"
            @save="save(ADMIN_ROLES.COACHES, $event)"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { AdminUserList, AdminUsersMixin, FormUnits } from '@kickbox/common-admin';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import { ADMIN_ROLES } from '@kickbox/common-util/constants/admin-roles';
import FormCompany from './FormCompany';

export default {
  components: {
    appFormCompany: FormCompany,
    appAdminUserList: AdminUserList,
    appFormUnits: FormUnits
  },
  mixins: [AdminUsersMixin],
  props: {
    slug: {
      type: String,
      default: ''
    },
    startingTab: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      active: this.startingTab,
      FEATURE_NAME,
      ADMIN_ROLES
    };
  },
  computed: {
    company() {
      return this.$store.getters.companyBySlug(this.slug);
    }
  },
  methods: {
    goToCompanyList($event) {
      if ($event) {
        return this.$store.commit('updateCompany', $event);
      }
      return this.$router.push({ name: 'CompaniesManage' });
    },
    updateStore(adminUsers, adminUserType) {
      this.$store.commit('setAdminUsersByCompanySlug', {
        slug: this.slug,
        adminUsers,
        adminUserType
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  /deep/ .v-window__container {
    margin-top: 30px;
  }
</style>
