<template>
  <v-container>
    <app-card-logo
      :companies="companies"
      type="company"
      @buttonClick="goToCreateNewCompany"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { CompanyService, CardsLogo } from '@kickbox/common-admin';

export default {
  components: {
    appCardLogo: CardsLogo
  },
  data() {
    return {
      search: ''
    };
  },
  computed: {
    ...mapGetters([
      'companies'
    ])
  },
  created() {
    this.$store.dispatch('setIsLoading', { companies: true });
    CompanyService.allCompanies()
      .then((companies) => {
        this.$store.commit('setCompanies', companies);
        const companiesDomains = this.$lodash
          .uniq(companies.map((company) => company.domain).flat());
        this.$store.commit('setCompaniesDomains', companiesDomains);
      })
      .finally(() => {
        this.$store.dispatch('setIsLoading', { companies: false });
      });
  },
  methods: {
    goToCreateNewCompany() {
      this.$router.push({ name: 'CreateCompany' });
    }
  },
};
</script>
