<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        lg="10"
      >
        <v-autocomplete
          :items="languages"
          :value="language"
          item-text="name"
          item-value="key"
          label="Language"
          @change="goTo"
        />
        <v-card>
          <v-card-title>
            <v-switch
              v-model="emptyOnly"
              :label="`Show missing translations only`"
              @change="updateFilteredTranslations"
            />
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              @input="updateFilteredTranslations"
            />
          </v-card-title>

          <v-data-table
            :headers="headers"
            :no-data-text="noData"
            :items="filteredTranslations"
            :loading="isLoading.translations"
            class="table-container"
            :footer-props="{
              itemsPerPageText: 'Translations per page:',
              itemsPerPageOptions: [15, 30, 60, 100]
            }"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="(item, index) of items"
                  :key="`${item.key}-${index}`"
                >
                  <td>{{ item.key }}</td>
                  <td>
                    <v-edit-dialog
                      :return-value.sync="item[language]"
                      lazy
                      large
                      @save="save(item)"
                    >
                      {{ item[language] }}
                      <template v-slot:input>
                        <v-textarea
                          v-model="item[language]"
                          label="Edit"
                          single-line
                          counter
                        />
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td class="immutable">
                    {{ item.en }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import languageService from '@/services/languageService';

export default {
  data() {
    return {
      exportDataFields: {
        Email: 'email',
        'Creation Date': 'creationDate'
      },
      search: '',
      searchResult: [],
      languages: [],
      language: this.$route.params.language.toUpperCase(),
      emptyOnly: false,
      headers: [
        { text: 'Key', value: 'key' },
        { text: 'Translation', value: 'translation' },
        { text: 'English', value: 'objectId' }
      ],
      translations: [],
      filteredTranslations: []
    };
  },
  computed: {
    ...mapGetters([
      'isLoading'
    ]),
    noData() {
      return this.isLoading.translations ? 'loading...' : 'No translations.';
    }
  },
  async beforeRouteUpdate(to, from, next) {
    this.language = to.params.language.toUpperCase();
    this.initialize();
    next();
  },
  async created() {
    this.initialize();
  },
  methods: {
    async save(changedTranslation) {
      const { language } = this;
      const { id } = changedTranslation;
      const text = changedTranslation[language];
      await languageService.saveTranslation({ id, language, text });
      this.updateFilteredTranslations();
      this.$store.dispatch('showSnackBar', { text: 'Translation saved.' });
    },
    async initialize() {
      this.$store.dispatch('setIsLoading', { translations: true });
      this.languages = await languageService.getLanguages();
      this.translations = await languageService.getAllKeys(this.language);
      this.updateFilteredTranslations();
      this.$store.dispatch('setIsLoading', { translations: false });
    },
    goTo(language) {
      this.$router.push(`${language}`);
    },
    updateFilteredTranslations() {
      const customSearch = this.search.toString().toLowerCase();

      let searchResult = this.translations.filter((row) => {
        const translation = row[this.language] || '';
        return row.key.toLowerCase().includes(customSearch)
          || row.en.toLowerCase().includes(customSearch)
          || translation.toLowerCase().includes(customSearch);
      });

      if (this.emptyOnly) {
        searchResult = searchResult.filter((t) => !t[this.language]);
      }
      this.filteredTranslations = searchResult;
    }
  }
};
</script>

<style scoped>
  .immutable {
    color: rgba(0, 0, 0, .35)
  }
</style>
