<template>
  <div>
    <app-layout
      :items="items"
      :menu-names="menuNames"
    />
  </div>
</template>

<script>
import { Layout } from '@kickbox/common-admin';

export default {
  components: {
    appLayout: Layout
  },
  data() {
    return {
      items: [
        {
          action: 'dashboard',
          menu: 'Dashboard',
          to: { name: 'Dashboard' }
        },
        {
          action: 'business',
          menu: 'Companies',
          items: [
            {
              value: 'Manage',
              to: { name: 'CompaniesManage' }
            }
          ]
        },
        {
          action: 'email',
          menu: 'Landing Page',
          items: [
            {
              value: 'Email List',
              to: { name: 'EmailsList' }
            }
          ]
        },
        {
          action: 'assignment',
          menu: 'Default Content',
          items: [
            {
              value: 'Content',
              to: { name: 'Content' }
            },
            {
              value: 'Email coaching',
              to: { name: 'Emails' }
            },
            {
              action: 'local_offer',
              value: 'Tags',
              to: { name: 'TagList' }
            }
          ]
        },
        {
          action: 'book',
          menu: 'Extra Mile',
          items: [
            {
              value: 'Chapters',
              to: { name: 'ExtraMileChapters' }
            }
          ]
        },
        {
          action: 'language',
          menu: 'Languages',
          to: { name: 'Languages' }
        }
      ],
      menuNames: {
        CompaniesManage: 'Manage Companies',
        CreateCompany: 'Create Company',
        EditCompany: 'Edit Company',
        TagList: 'Manage Default Tags',
        Languages: 'Languages',
        ExtraMileChapters: 'Chapters',
        ExtraMilePages: 'Pages',
      }
    };
  }
};
</script>
