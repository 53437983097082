<template>
  <v-row>
    <v-col
      cols="12"
      offset-sm="1"
      md="10"
      lg="7"
      xl="6"
    >
      <app-form-company />
    </v-col>
  </v-row>
</template>

<script>
import FormCompany from './FormCompany';

export default {
  components: {
    appFormCompany: FormCompany
  }
};
</script>
