import Vue from 'vue';
import Router from 'vue-router';
import { TagList } from '@kickbox/common-admin';
import Layout from '@/components/Layout';
import CompaniesList from '@/components/companies/CompaniesList';
import CreateCompany from '@/components/companies/CreateCompany';
import EditCompany from '@/components/companies/EditCompany';
import EmailsList from '@/components/landingpage/EmailsList';
import TranslationList from '@/components/translations/TranslationList';
import Languages from '@/components/translations/Languages';
import Content from '@/components/defaultContent/Content';
import Emails from '@/components/defaultContent/Emails';
import Login from '@/components/Login';
import AuthGuard from '@/router/authGuard';
import Dashboard from '@/components/Dashboard';
import ExtraMileChapters from '@/components/extraMile/Chapters';
import ExtraMilePages from '@/components/extraMile/Pages';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/',
      alias: '/meta',
      name: 'Home',
      component: Layout,
      beforeEnter: AuthGuard,
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'companies/manage',
          name: 'CompaniesManage',
          component: CompaniesList
        },
        {
          path: 'companies/manage/create-company',
          name: 'CreateCompany',
          component: CreateCompany
        },
        {
          path: 'companies/manage/edit/:slug',
          name: 'EditCompany',
          component: EditCompany,
          props: true
        },
        {
          path: 'landingpage/emails-list',
          name: 'EmailsList',
          component: EmailsList
        },
        {
          path: 'languages/list',
          name: 'Languages',
          component: Languages
        },
        {
          path: 'languages/translations/:language',
          name: 'TranslationList',
          component: TranslationList,
          props: true
        },
        {
          path: 'tags/tag-list',
          name: 'TagList',
          component: TagList
        },
        {
          path: 'defaultContent/content',
          name: 'Content',
          component: Content
        },
        {
          path: 'defaultContent/emails',
          name: 'Emails',
          component: Emails
        },
        {
          path: 'extraMile/chapters',
          name: 'ExtraMileChapters',
          component: ExtraMileChapters
        },
        {
          path: 'extraMile/pages',
          name: 'ExtraMilePages',
          component: ExtraMilePages,
          props: true
        },
      ]
    }
  ]
});

export default router;
